<script>
import { HorizontalBar } from 'vue-chartjs'
import { standardDateTimeFormat, standardTimeFormat, isSameDate } from '@/lib/utils'

export default {
    extends: HorizontalBar,
    props: ['hits'],
    data: () => ({
        colors: {
            assignable: '#4caf50',
            unassignable: '#ffb300',
            others: '#ccc'
        }
    }),
    computed: {
        xMin() { return Math.min(...this.hits.map((h) => (h.CreationTime))); },
        xMax() { return Math.max(...this.hits.map((h) => (h.Expiration))); }
    },
    methods: {
        //standardDateFormat,
        //standardDateTimeFormat
    },
    mounted() {
        const chartdata = 
            {
            labels: this.hits.map((h) => (h.HITId)),
                datasets: [
                    {
                        backgroundColor: this.hits.map((h) => {
                                if (h.HITStatus=='Assignable') {
                                    return this.colors.assignable;
                                } else if (h.HITStatus=='Unassignable') {
                                    return this.colors.unassignable;
                                } else  {
                                    return this.colors.others;
                                }
                            }),
                        data: this.hits.map((h) => ([h.CreationTime, h.Expiration]))
                    }
                ]
            };
        const options = {
            legend: {
                labels: {
                    generateLabels: () => {
                        return [
                            { text: 'Assignable', lineWidth: 0, fillStyle: this.colors.assignable },
                            { text: 'Unassignable', lineWidth: 0, fillStyle: this.colors.unassignable },
                            { text: 'Reviewing / Reviewable / Disposed', lineWidth: 0, fillStyle: this.colors.others },
                        ];
                    }
                }
            },
            tooltips: {
                displayColors: false,
                callbacks: {
                    label: (item, data) => {
                        const hit = this.hits[item.index];
                        const [creationTime, expiration] = data.datasets[0].data[item.index];
                        const creationTimeFormatted = standardDateTimeFormat(creationTime);
                        const expirationFormatted = standardDateTimeFormat(expiration);

                        return [
                                `HIT Status: ${hit.HITStatus}`,
                                `Max Assignments: ${hit.MaxAssignments}`,
                                `(Available: ${hit.NumberOfAssignmentsAvailable}, Pending: ${hit.NumberOfAssignmentsPending}, Completed: ${hit.NumberOfAssignmentsCompleted})`,
                                `Created at: ${creationTimeFormatted}`,
                                `Expires at: ${expirationFormatted}`,
                            ];
                    }
                }
            },
            scales: {
                xAxes: [
                    {
                        ticks: {
                            min: this.xMin,
                            max: this.xMax,
                            //minRotation: 30,
                            stepSize: (this.xMax-this.xMin)/5,
                            callback: (value,index,values) => {
                                return (index==0 || !isSameDate(value, values[index-1]))
                                    ? standardDateTimeFormat(value)
                                    : standardTimeFormat(value);
                            }
                        }
                    }
                ],
                yAxes: [
                    { display: false }
                ]
            }
        };
        this.renderChart(chartdata, options);
    }
}
</script>
