var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("page-toolbar", {
                attrs: {
                  "previous-page-label": _vm.$t(
                    "console.platforms.mturk.hits.toolbar.prevPageLabel"
                  ),
                  title: _vm.$t("console.platforms.mturk.hits.toolbar.title"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "contents",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              text: "",
                              color: "indigo",
                              to: "/console/platform/mturk/hit/create/",
                            },
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("mdi-plus"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "console.platforms.mturk.hits.toolbar.buttons.create"
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "11" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  dense: "",
                  "show-expand": "",
                  "sort-desc": "",
                  "must-sort": "",
                  headers: _vm.headers,
                  items: _vm.hitBatchItems,
                  "single-expand": false,
                  expanded: _vm.expanded,
                  "item-key": "hit_type.id",
                  loading: _vm.loading,
                  "sort-by": "createdAt",
                },
                on: {
                  "update:expanded": function ($event) {
                    _vm.expanded = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-card-title",
                          [
                            _c("v-spacer"),
                            _c("v-text-field", {
                              attrs: {
                                "single-line": "",
                                "hide-details": "",
                                "append-icon": "mdi-magnify",
                                label: _vm.$t(
                                  "console.platforms.mturk.hits.search"
                                ),
                              },
                              model: {
                                value: _vm.searchQuery,
                                callback: function ($$v) {
                                  _vm.searchQuery = $$v
                                },
                                expression: "searchQuery",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("v-card-text", [
                          _c(
                            "div",
                            { staticClass: "text-right" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    outlined: "",
                                    disabled: _vm.loading,
                                    color: "indigo",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.refreshTable()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-refresh"),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "console.platforms.mturk.hits.refreshButton"
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.assignable",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _vm.statusCounts.HITStatus[
                                        item.hit_type.id
                                      ].Assignable > 0
                                        ? _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                { attrs: { color: "success" } },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v("mdi-circle-medium")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "console.platforms.mturk.hits.table.assignableTooltip"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.name",
                    fn: function ({ item }) {
                      return [_vm._v(" " + _vm._s(item.name) + " ")]
                    },
                  },
                  {
                    key: "item.projectName",
                    fn: function ({ item }) {
                      return [_vm._v(" " + _vm._s(item.project_name) + " ")]
                    },
                  },
                  {
                    key: "item.hitsStatus",
                    fn: function ({ item }) {
                      return [
                        _c("v-progress-linear", {
                          attrs: {
                            striped: "",
                            color: "indigo",
                            value:
                              100 -
                              (_vm.statusCounts.HITStatus[item.hit_type.id]
                                .Reviewable /
                                _vm.statusCounts.all[item.hit_type.id]) *
                                100,
                          },
                        }),
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(" open: "),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.statusCounts.HITStatus[item.hit_type.id]
                                  .Assignable
                              )
                            ),
                          ]),
                          _vm._v(", closed: "),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.statusCounts.HITStatus[item.hit_type.id]
                                  .Reviewable
                              )
                            ),
                          ]),
                          _vm._v(" (Total "),
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.statusCounts.all[item.hit_type.id])
                            ),
                          ]),
                          _vm._v(" HITs) "),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.createdAt",
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.standardDateTimeFormat(item._created_at)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.refresh",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              icon: "",
                              loading:
                                _vm.batchProgresses[item.id].refresh.loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.refreshBatch(item)
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-refresh")])],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "expanded-item",
                    fn: function ({ headers, item }) {
                      return [
                        _c(
                          "td",
                          { attrs: { colspan: headers.length } },
                          [
                            _vm.batchProgresses[item.id].operation.shown
                              ? _c(
                                  "div",
                                  [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        value:
                                          ((_vm.batchProgresses[item.id]
                                            .operation.counts.success +
                                            _vm.batchProgresses[item.id]
                                              .operation.counts.error) /
                                            _vm.batchProgresses[item.id]
                                              .operation.counts.all) *
                                          100,
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center font-weight-medium mt-2",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.batchProgresses[item.id]
                                                .operation.type
                                            ) +
                                            " HITs: " +
                                            _vm._s(
                                              _vm.batchProgresses[item.id]
                                                .operation.counts.success +
                                                _vm.batchProgresses[item.id]
                                                  .operation.counts.error
                                            ) +
                                            " / " +
                                            _vm._s(
                                              _vm.batchProgresses[item.id]
                                                .operation.counts.all
                                            ) +
                                            " (Success: " +
                                            _vm._s(
                                              _vm.batchProgresses[item.id]
                                                .operation.counts.success
                                            ) +
                                            ", Error: " +
                                            _vm._s(
                                              _vm.batchProgresses[item.id]
                                                .operation.counts.error
                                            ) +
                                            ") "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "mt-4",
                                attrs: { align: "right" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      small: "",
                                      outlined: "",
                                      color: "warning",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$refs.expire.show()
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("mdi-cancel"),
                                    ]),
                                    _vm._v("Expire"),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      small: "",
                                      outlined: "",
                                      color: "error",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$refs.delete.show()
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("mdi-trash-can-outline"),
                                    ]),
                                    _vm._v("Delete"),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-expansion-panels",
                              {
                                staticClass: "py-4",
                                attrs: {
                                  multiple: "",
                                  dense: "",
                                  value: [0, 1],
                                },
                              },
                              [
                                _c(
                                  "v-expansion-panel",
                                  [
                                    _c(
                                      "v-expansion-panel-header",
                                      { staticClass: "py-0" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "text-subtitle-2 ma-0",
                                          },
                                          [
                                            _vm._v(
                                              " HIT Type (" +
                                                _vm._s(item.hit_type.id) +
                                                ") "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-expansion-panel-content",
                                      { staticClass: "pa-0" },
                                      [
                                        _c(
                                          "v-simple-table",
                                          { attrs: { dense: "" } },
                                          [
                                            _c(
                                              "tbody",
                                              [
                                                _vm.credentials &&
                                                item.hits.length > 0
                                                  ? _c("tr", [
                                                      _c(
                                                        "th",
                                                        {
                                                          attrs: {
                                                            width: "250",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "HIT Group URL"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: _vm.hitGroupURL(
                                                                  item.hits[0]
                                                                    .HITGroupId,
                                                                  _vm
                                                                    .credentials
                                                                    .is_sandbox
                                                                ),
                                                                target:
                                                                  "_blank",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.hitGroupURL(
                                                                    item.hits[0]
                                                                      .HITGroupId,
                                                                    _vm
                                                                      .credentials
                                                                      .is_sandbox
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-open-in-new"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._l(
                                                  [
                                                    "Title",
                                                    "Description",
                                                    "Keywords",
                                                    "Reward",
                                                  ],
                                                  function (key) {
                                                    return _c(
                                                      "tr",
                                                      {
                                                        key: `expansion-HITType-${item.hit_type.id}-${key}`,
                                                      },
                                                      [
                                                        _c("th", [
                                                          _vm._v(_vm._s(key)),
                                                        ]),
                                                        _c("td", [
                                                          key === "Keywords" &&
                                                          item.hit_type[key] !==
                                                            ""
                                                            ? _c(
                                                                "div",
                                                                _vm._l(
                                                                  item.hit_type[
                                                                    key
                                                                  ].split(","),
                                                                  function (
                                                                    keyword
                                                                  ) {
                                                                    return _c(
                                                                      "v-chip",
                                                                      {
                                                                        key: `keyword-tooltip-${item.hit_type.id}-${keyword}`,
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              keyword
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            : key === "Reward"
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "$" +
                                                                    _vm._s(
                                                                      item
                                                                        .hit_type[
                                                                        key
                                                                      ]
                                                                    )
                                                                ),
                                                              ])
                                                            : _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .hit_type[
                                                                      key
                                                                    ]
                                                                  )
                                                                ),
                                                              ]),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _c("tr", [
                                                  _c("th", [
                                                    _vm._v(
                                                      "AssignmentDurationInSeconds"
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.hit_type
                                                          .AssignmentDurationInSeconds
                                                      ) +
                                                        " (" +
                                                        _vm._s(
                                                          _vm.secondsToFormattedTime(
                                                            item.hit_type
                                                              .AssignmentDurationInSeconds,
                                                            [
                                                              "hrs",
                                                              "mins",
                                                              "secs",
                                                            ]
                                                          )
                                                        ) +
                                                        ")"
                                                    ),
                                                  ]),
                                                ]),
                                                _c("tr", [
                                                  _c("th", [
                                                    _vm._v(
                                                      "AutoApprovalDelayInSeconds"
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.hit_type
                                                          .AutoApprovalDelayInSeconds
                                                      ) +
                                                        " (" +
                                                        _vm._s(
                                                          _vm.secondsToFormattedTime(
                                                            item.hit_type
                                                              .AutoApprovalDelayInSeconds,
                                                            [
                                                              "wks",
                                                              "days",
                                                              "hrs",
                                                              "mins",
                                                              "secs",
                                                            ]
                                                          )
                                                        ) +
                                                        ")"
                                                    ),
                                                  ]),
                                                ]),
                                                _vm._l(
                                                  _vm.dropTuttiHITBatchQualTypes(
                                                    item.hit_type
                                                      .QualificationRequirements
                                                  ),
                                                  function (requirement, i) {
                                                    return _c(
                                                      "tr",
                                                      {
                                                        key: `${item.hit_type.id}-QualificationRequirement${i}`,
                                                      },
                                                      [
                                                        _c("th", [
                                                          _vm._v(
                                                            "QualificationRequirement " +
                                                              _vm._s(i + 1)
                                                          ),
                                                        ]),
                                                        _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "v-simple-table",
                                                              {
                                                                attrs: {
                                                                  dense: "",
                                                                },
                                                              },
                                                              [
                                                                _c("tbody", [
                                                                  _c("tr", [
                                                                    _c("th", [
                                                                      _vm._v(
                                                                        "QualificationTypeId"
                                                                      ),
                                                                    ]),
                                                                    _c("td", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          requirement.QualificationTypeId
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]),
                                                                  _c("tr", [
                                                                    _c("th", [
                                                                      _vm._v(
                                                                        "Comparator"
                                                                      ),
                                                                    ]),
                                                                    _c("td", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          requirement.Comparator
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]),
                                                                  "IntegerValues" in
                                                                  requirement
                                                                    ? _c("tr", [
                                                                        _c(
                                                                          "th",
                                                                          [
                                                                            _vm._v(
                                                                              "IntegerValues"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                requirement.IntegerValues.join(
                                                                                  ", "
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ])
                                                                    : _vm._e(),
                                                                  "LocaleValues" in
                                                                  requirement
                                                                    ? _c("tr", [
                                                                        _c(
                                                                          "th",
                                                                          [
                                                                            _vm._v(
                                                                              "LocaleValues"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                requirement.LocaleValues.map(
                                                                                  (
                                                                                    l
                                                                                  ) =>
                                                                                    Object.entries(
                                                                                      l
                                                                                    )
                                                                                      .map(
                                                                                        (
                                                                                          _l
                                                                                        ) =>
                                                                                          _l[1]
                                                                                      )
                                                                                      .join(
                                                                                        "-"
                                                                                      )
                                                                                ).join(
                                                                                  ", "
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ])
                                                                    : _vm._e(),
                                                                  _c("tr", [
                                                                    _c("th", [
                                                                      _vm._v(
                                                                        "ActionsGuarded"
                                                                      ),
                                                                    ]),
                                                                    _c("td", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          requirement.ActionsGuarded
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-expansion-panel",
                                  [
                                    _c(
                                      "v-expansion-panel-header",
                                      { staticClass: "py-0" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "text-subtitle-2 ma-0",
                                          },
                                          [
                                            _vm._v(
                                              "HITs (" +
                                                _vm._s(item.hits.length) +
                                                ")"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-expansion-panel-content",
                                      [
                                        _c("hits-chart", {
                                          key: `batch-chart-${item.id}-${
                                            _vm.batchProgresses[item.id].refresh
                                              .count
                                          }`,
                                          attrs: {
                                            hits: item.hits,
                                            height: 60,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("tutti-dialog-alert", {
                          ref: "expire",
                          on: {
                            confirm: function ($event) {
                              return _vm.expireHITs(item)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [_vm._v(" Expire HITs in HIT Group ")]
                                },
                                proxy: true,
                              },
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    _c("v-card-text", [
                                      _vm._v(
                                        " Do you really wish to expire all HITs in this HIT Group? "
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("tutti-dialog-alert", {
                          ref: "delete",
                          on: {
                            confirm: function ($event) {
                              return _vm.deleteHITs(item)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [_vm._v(" Delete HITs in HIT Group ")]
                                },
                                proxy: true,
                              },
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    _c("v-card-text", [
                                      _vm._v(
                                        " Do you really wish to delete all HITs in this HIT Group? "
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.selectedHITTypes,
                  callback: function ($$v) {
                    _vm.selectedHITTypes = $$v
                  },
                  expression: "selectedHITTypes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("tutti-snackbar", { ref: "snackbar" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }